import { getParameterByName, updateQueryStringParameter } from './helpers/utils'

$(() => {
  let username = getParameterByName('username');
  username = username || getParameterByName('u');

  if (username) {
    $('#user_username').val(username)
    $('#user_password').focus()
  } else {
    $('#user_username').focus()
  }

  $('.language-link').on('click', (e) => {
    e.preventDefault()
    let language = e.currentTarget.dataset.language
    window.location.href = updateQueryStringParameter(window.location.href, 'locale', language)
  })

  $('#generate-pass').click(e => {
    e.preventDefault()
    e.stopPropagation()
    const a = 'abcdefghijklmnopqrstuvwxyz'
    const b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const c = '0123456789'

    const pwd = `${Math.random().toString(36).slice(-5)}${a[~~(Math.random()*a.length)]}${b[~~(Math.random()*b.length)]}${c[~~(Math.random()*c.length)]}`
    $('#user_password').val(pwd)
    $('#user_password_confirmation').val(pwd)
  })
})
